import React from 'react';
import { graphql } from 'gatsby'
import LegacyContainer from '../components/legacy-container';
import SEO from '../components/SEO';
import Testimonials from '../components/modular/Testimonials/Testimonials';
import PageTitle from '../components/modular/PageTitle/PageTitle';

export default function TestimonialsPage({ data }) {
  const reviews = data.allMarkdownRemark.edges[0].node.frontmatter.reviews;
  return (
    <LegacyContainer>
      <SEO pageTitle={data.allMarkdownRemark.edges[0].node.frontmatter.heading} />
      <div className="content-container brighter-content-container">
        <div className="content">
          <PageTitle title={data.allMarkdownRemark.edges[0].node.frontmatter.heading} />
          <Testimonials reviews={reviews} />
        </div>
      </div>
    </LegacyContainer>
  );
}

export const testimonialsQuery = graphql`
  query TestimonialsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonials-page" } }}
      limit: 1
    ) {
      edges {
        node {
          html
          headings {
            depth
            value
          }
          frontmatter {
            heading
            reviews {
              name,
              review
            }
          }
        }
      }
    }
  }
`