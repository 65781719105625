import React from 'react'
import styles from './Testimonials.module.css'

const Testimonials = (props) => {
  return (
    <div className={styles.container}>
      <ul className="rsm-testimonials-ul">
        {props.reviews.map((review, index) => {
          return (
            <li className="rsm-testimonials-li" key={index}>
              <span className="rsm-testimonials-text">
                <p>"{review.review}"</p>
                <p className="rsm-testimonials-reference">{review.name}</p>
              </span>
            </li>
          );
        })}

      </ul>
    </div>
  )
}

export default Testimonials